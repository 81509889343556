import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';

import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import api from '../../API/api'


const Counter = () => {

    const [count, setCount] = useState({});
    const [load, setLoad] = useState(false);

    useEffect(() => {
        countSection();
    }, []);

    const countSection = () => {
        setLoad(true);
        api.countSection()
            .then((res) => {
                setLoad(false);
                console.log(res);
                setCount(res.data);
            })
            .catch((err) => {
                setLoad(false);
                console.log(err.response);
            })


    }

    return (
        <>

            <Card>




                {/* *********** */}
                <div className="counter-up">
                    <div className="content">
                        <div className="box">
                            <div className="icon"><LibraryBooksIcon fontSize="large" /></div>
                            <div className="counter">{count.tot_article && count.tot_article}</div>
                            <div className="text">Total Articles</div>

                        </div>
                        <div className="box">
                            <div className="icon"><MenuBookIcon fontSize="large" /></div>
                            <div className="counter">{count.tot_journal && count.tot_journal}</div>
                            <div className="text">Journals</div>
                        </div>
                        <div className="box">
                            <div className="icon"><DownloadIcon fontSize="large" /></div>
                            <div className="counter">{count.visitor && '65'}</div>
                            <div className="text">Total Downloads</div>
                        </div>
                        <div className="box">
                            <div className="icon"><LibraryBooksIcon fontSize="large" /></div>
                            <div className="counter">{count.visitor && count.visitor}</div>
                            <div className="text">Visitors</div>
                        </div>
                    </div>
                </div>

            </Card>
        </>
    )
}

export const Counter2 = () => {

    const [count, setCount] = useState({});
    const [load, setLoad] = useState(false);

    useEffect(() => {
        countSection();
    }, []);

    const countSection = () => {
        setLoad(true);
        api.countSection()
            .then((res) => {
                setLoad(false);
                console.log(res);
                setCount(res.data);
            })
            .catch((err) => {
                setLoad(false);
                console.log(err.response);
            })


    }
    return (
        <>
            <section id="count" className="py-5">
                <div className="container py-5">
                    <div className="row py-5">
                        <div className="col-lg-3 first-count">
                            <div className="card shadow-sm rounded-0 py-5 text-center text-white">
                                <div className="card-body">
                                    <div className="h5 font-weight-bold letter-spacing-sm text-uppercase">Total Articles </div>
                                    <p id="number1" className="number first-number-count">{count.tot_article && count.tot_article}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 second-count">
                            <div className="card shadow-sm rounded-0 py-5 text-center text-white">
                                <div className="card-body">
                                    <div className="h5 font-weight-bold letter-spacing-sm text-uppercase">Journals</div>
                                    <p id="number2" className="number second-number-count">{count.tot_journal && count.tot_journal}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 third-count">
                            <div className="card shadow-sm rounded-0 py-5 text-center text-white">
                                <div className="card-body">
                                    <div className="h5 font-weight-bold letter-spacing-sm text-uppercase">Total Downloads</div>
                                    <p id="number3" className="number third-number-count">{count.visitor && '652'}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 fourth-count">
                            <div className="card shadow-sm rounded-0 py-5 text-center text-white">
                                <div className="card-body">
                                    <div className="h5 font-weight-bold letter-spacing-sm text-uppercase">Visitors</div>
                                    <p id="number4" className="number fourth-number-count">{count.visitor && count.visitor}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Counter;