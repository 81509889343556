import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { Divider } from '@mui/material';

// import DownAnimation from '../other/downAnimation';

import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span>Get connected with us on social networks:</span>
        </div>

        <div>
          <a href='' className='me-4 text-reset'>
            <FacebookRoundedIcon />
          </a>
          <a href='' className='me-4 text-reset'>
            <TwitterIcon />
          </a>
          <a href='' className='me-4 text-reset'>
            <InstagramIcon />
          </a>
          <a href='' className='me-4 text-reset'>
            <LinkedInIcon />
          </a>
        </div>
      </section>

      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>

            <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
              <p>
                <a href='/AllJournals' className='text-reset'>
                  Journal
                </a>
              </p>
              <p>
                <a href='/refundpolicy' className='text-reset'>
                  CANCELLATION/REFUND POLICY
                </a>
              </p>
              <p>
                <a href='/author-instruction' className='text-reset'>
                  Authors Guidlines
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Contact
                </a>
              </p>
            </MDBCol>

            <MDBCol md="5" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <MDBIcon icon="gem" className="me-3" />
                Open Access License
              </h6>
              {/* <img src="/assets/openaccess.png" />
              <p>
                This work is licensed under a Creative Commons Attribution-NonCommercial 4.0 International License.
              </p> */}


              <a rel="license" href="http://creativecommons.org/licenses/by-nc/4.0/">
                <img alt="Creative Commons License" style={{ borderWidth: '0' }} src="https://i.creativecommons.org/l/by-nc/4.0/88x31.png" />
              </a>
              <br />
              This work is licensed under a <a rel="license" href="http://creativecommons.org/licenses/by-nc/4.0/">Creative Commons Attribution-NonCommercial 4.0 International License</a>


            </MDBCol>



            {/* <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
              <p>
                <a href='#!' className='text-reset'>
                  Journal
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Author
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Reviewer
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Contact
                </a>
              </p>
            </MDBCol> */}

            <MDBCol md="5" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <MDBIcon icon="home" className="me-2" />
                Building No: 34, Uttar Nowabil, PO:- Ambari, PS:- Murajhar,
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                contact@issrapublisher.com
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" /> +917636929055
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" /> +916000892709
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>

        <strong>  © Copyright 2023 ISSRA Publisher. All Rights Reserved </strong>
      </div>
      <div className='text-center p-1' style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
        {/* © 2023 Copyright: */}
        Developed by:
        <a className='text-reset fw-bold' href='https://pageuptechnologies.com'>
          pageuptechnologies.com
        </a>
      </div>
    </MDBFooter>
  );

}




export const Footer2 = () => {
  return (
    <>
      {/* <DownAnimation /> */}

      <footer className="text-center footer text-center bg-dark text-white">
        <div className="container p-4">
          {/* <div className="row justify-content-between align-items-center">
            <div className="col-3">
              <img src="/assets/mainLogo.png" alt="logo" height={70} />
            </div>
            <div className="col-3 m-2">
              <FacebookRoundedIcon />
              <TwitterIcon />
              <InstagramIcon />
            </div>
          </div>
          <Divider sx={{ bgcolor: 'white' }} /> */}

          <div className="row  text-md-start mt-5">

            <div className="col-md-3 ">
              <h5 className="pb-3"> Quick links </h5>
              <ul className="list-style ps-0">
                <div className="pb-0"> <ArrowRightIcon /> Home</div>
                <div className="pb-0"> <ArrowRightIcon /> Journal</div>
                <div className="pb-0"> <ArrowRightIcon /> About Us</div>
                <div className="pb-0"> <ArrowRightIcon /> Contact Us</div>
              </ul>
            </div>

            {/* <div className="col-md-3">
         <h5 className="pb-3">Open Access Licence </h5>
          <ul className="list-style ps-0">
            <div className="pb-3">This work is licensed under a Creative Commons Attribution-NonCommercial 4.0 International License.</div>
          </ul>
        </div> */}

            <div className="col-md-5 ">
              <h5 className="pb-3 pl-2">Useful Links </h5>
              <ul className="list-style ps-0">
                <div className="pb-0"><Link to="PrivacyPolicy" className='text-white' style={{ textDecoration: 'none' }}>  <ArrowRightIcon /> Privacy Policy </Link> </div>
                <div className="pb-0"><Link to="TermsAndConditions" className='text-white' style={{ textDecoration: 'none' }}> <ArrowRightIcon /> Term & Conditions </Link> </div>
                <div className="pb-0"><Link to="RefundPolicy" className='text-white' style={{ textDecoration: 'none' }}><ArrowRightIcon /> Refund Policy </Link></div>
              </ul>

              {/* <h5 className="pb-2">Open Access Licence <img src='/assets/open.png' width={100} /> </h5> */}

              {/* <ul className="list-style ps-0">
            <div className="pb-2">This work is licensed under a Creative Commons Attribution-NonCommercial 4.0 International License.</div>
          </ul> */}


            </div>

            <div className="col-md-4 ">
              <h5 className="pb-3"> Contact Info </h5>
              <ul className="list-style ps-0">
                <div className="pb-3"> <LocationOnIcon /> Building No: 34, Uttar Nowabil  PO:- Ambari, PS:- Murajhar  Hojai, Assam, India 782445</div>
                <div className="pb-3"> <PermPhoneMsgIcon /> +91 7002576280/+91 7636929055</div>
                <div className="pb-3"> <ContactMailIcon /> issrapublisher@gmail.com</div>
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="">
                <div className="p-2">
                  This work is licensed under a Creative Commons Attribution-NonCommercial 4.0 International License.
                  <img alt="Creative Commons License" src="https://i.creativecommons.org/l/by-nc/4.0/88x31.png" />
                </div>
              </div>
            </div>
          </div>

        </div>
      </footer>
      <div>
        <div className="bg-danger p-2">
          <div className="text-center text-white">
            <span><strong>Developed By:</strong> <a className='text-white' href="http://pageuptechnologies.com/">pageuptechnologies.com</a></span>
          </div>
        </div>
      </div>

    </>
  )
}