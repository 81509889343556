import React from 'react'
import Card from '@mui/material/Card';
import { CardActions } from '@mui/material';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';
import 'swiper/css/autoplay';
import 'swiper/css';


const Indexing = (props) => {
    return (
        <>
            <Card>

                {props.indexings &&
                    <div className='pt-2'>
                        <Card>
                            <div className='bg-primary p-2 text-white botton-line '>
                                INDEXING
                            </div>

                            {props.indexings &&
                                <Card>
                                    <div className='m-3 p-2 '>
                                        <Swiper slidesPerView={6}
                                            loop={false}
                                            modules={[Autoplay, Scrollbar, Pagination]}
                                            //autoplay={false}
                                            spaceBetween={20}
                                            autoplay={{
                                                delay: 3000,
                                                disableOnInteraction: false,
                                            }}
                                            speed={800}
                                            pagination={{ clickable: true }}

                                           

                                        >

                                            <div className=''>
                                                {/* <div className='col-12'> */}
                                                {props.indexings.map((indexing) => (
                                                    <SwiperSlide  sx={{ alignItems: 'center' }}>
                                                        <div className='border border-danger '>
                                                            <img src={`${process.env.REACT_APP_BACKEND}assets/indexing/${indexing.img}`} className='col-11' alt='indexing' />
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                                {/* </div> */}
                                            </div>

                                        </Swiper>
                                    </div>
                                </Card>
                            }
                        </Card>
                    </div>
                }
            </Card>
        </>
    )
}

export default Indexing;